<div class="parallax-mirror" style="
    visibility: visible;
    z-index: -100;
    position: fixed;
    top: 58.1676px;
    left: 0px;
    overflow: hidden;
    transform: translate3d(0px, 0px, 0px);
    height: 609px;
    width: 100%;
  ">
  <img class="parallax-slider" src="../../../assets/images/parallax-07.jpg" style="
      transform: translate3d(0px, 0px, 0px);
      position: absolute;
      height: 535px;
      width: 100%;
      max-width: none;
    " />
</div>

<!-- HEADER -->
<!-- MAIN -->
<main role="main">
  <!-- Section 1 -->
  <article>
    <header class="section background-green background-transparent text-center" data-parallax="scroll"
      data-image-src="../../../assets/images/parallax-07.jpg">
      <div class="line">
        <div class="margin-top-bottom-20">
          <!-- Title Strings -->
          <div id="typed-strings" style="display: none">
            <p>klein</p>
            <p>promo</p>
            <p>nieuw</p>
          </div>
          <h1 class="text-white text-size-40 text-thin text-line-height-1">
            Groothandel in klein materiaal <br />
            voor tandartsen en dentaal technische bedrijven
          </h1>
          <p class="text-white text-size-16 margin-bottom-20">
            Bent u op zoek naar een artikel, bekijk ons ruim assortiment in onze
            <strong>
              <a routerLink="/NL/webshop/tandarts" class="text-yellow-hover">webshop
              </a> </strong>!
          </p>
          <div class="line">
            <a class="button border-radius background-yellow text-size-14 text-dark text-strong text-primary-hover"
              href="https://view.publitas.com/providental/promo-web-nl-providental" target="_blank">Bekijk onze
              aanbieding online !</a>
          </div>
        </div>
      </div>
    </header>
  </article>
  <!-- Section 2 -->

  <section class="section background-white">
    <div class="line">
      <div class="margin">
        <div class="full-width text-center">
          <a href="https://view.publitas.com/providental/promo-web-nl-providental" target="_blank">
            <img src="../../../assets/images/Covers/cover.png" alt="Promotie NL" class="center"
              style="margin-top: -210px" />
          </a>
          <div class="full-width text-center">
            <div class="line">
              <h2 class="headline text-thin text-s-size-30">
                You <span class="text-primary">Name it</span>, We've got it !
              </h2>
              <p class="text-size-20 text-s-size-16 text-thin">
                Compromitteer niet op kwaliteit!
              </p>
              <p class="text-size-20 text-s-size-16 text-thin">
                Vindt u niet wat u zoekt ... <br />
                Bel of mail ons voor meer info: 03 226 00 81 /
                info&#64;providental.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section background-white padding">
    <div class="line">
      <div class="margin">
        <div class="s-12 m-12 l-4 margin-m-bottom">
          <a href="https://view.publitas.com/providental/promo-web-nl-providental" target="_blank">
            <img class="margin-bottom" src="../../assets/images/Aanbiedingen fabrikanten/PromoNL.jpg"
              alt="Promotie online NL" />
          </a>
          <h2 class="text-thin">
            <strong class="text-red">Aanbiedingen Tandartsen</strong>
          </h2>
          <p>
            <a href="https://view.publitas.com/providental/promo-web-nl-providental" target="_blank">- Promoties</a>
          </p>
          <p>
            <a routerLink="/NL/fabrikantaanbiedingen" target="_self">- Aanbiedingen fabrikanten ....</a>
          </p>
          <a class="text-more-info text-primary-hover"
            href="https://view.publitas.com/providental/promo-web-nl-providental" target="_blank">Lees meer
          </a>
        </div>
        <div class="s-12 m-12 l-4 margin-m-bottom">
          <a routerLink="/NL/webshop/tandarts" target="_self">
            <img class="margin-bottom" src="../../../assets/images/Aanbiedingen fabrikanten/Webshop.jpg"
              alt="Webshop tandartsen" />
          </a>
          <h2 class="text-thin text-green">
            <strong>Webshop</strong>
          </h2>
          <p>Vandaag besteld, vandaag naar u toegestuurd !</p>
          <a class="text-more-info text-primary-hover" routerLink="/NL/webshop/tandarts" target="_self">Bestel</a>
        </div>
        <div class="s-12 m-12 l-4 margin-m-bottom">
          <img class="margin-bottom" src="../../../assets/images/Aanbiedingen fabrikanten/Nieuws.jpg"
            alt="Ontdek hier nieuwe producten!" />
          <h2 class="text-thin">
            <strong>Nieuws !</strong>
          </h2>
          <p>- Nieuw in ons assortiment van ruim 60.000 producten !</p>
          <p>
            <a routerLink="/NL/fabrikantecatalogus" target="_self">- Graag nog een papieren versie van de catalogus
              ...</a>
          </p>
          <p>
            <a routerLink="/NL/fabrikantlinks" target="_self">- Links naar fabrikanten ...</a>
          </p>
          <a class="text-more-info text-primary-hover" routerLink="/NL/fabrikantnieuweproducten" target="_self">Ontdek
            het hier</a>
        </div>
      </div>
    </div>
  </section>

  <!-- Section 3 -->
  <!--<section class="section background-white padding">
      <div class="line">
        <div class="margin">
          <div class="s-12 m-12 l-4 margin-m-bottom">
            <img class="margin-bottom" src="../../assets/images/voorbeeld.jpg" alt="Op dit ogenblik geen aanbiedingen / tandtechnische labo's">
            <h3 class="text-thin">
              <strong>Aanbiedingen Tandtechnische labo's</strong>
            </h3>
            <p>Op dit ogenblik zijn er geen aanbiedingen !</p>
            <a class="text-more-info text-primary-hover" routerLink="/webshop/labo">Lees meer </a>
          </div>
          <div class="s-12 m-12 l-4 margin-m-bottom">
            <img class="margin-bottom" src="../../assets/images/webshop labo.jpg" alt="webshop tandtechnische labo's">
            <h3 class="text-thin text-blue">
              <strong>Webshop tandtechnische labo's</strong>
            </h3>
            <p>Vandaag besteld, vandaag naar u toegestuurd !</p>
            <a class="text-more-info text-primary-hover" routerLink="/webshop/labo">Bestel</a>
          </div>
          <div class="s-12 m-12 l-4 margin-m-bottom">
            <img class="margin-bottom" src="../../assets/images/voorbeeld.jpg" alt="Allerlei info!">
            <h3 class="text-thin">
              <strong>Allerlei !</strong>
            </h3>
            <p>
              <a routerLink="/NL/fabrikantecatalogus" target="_self">- Graag nog een papieren versie van de catalogus ...</a>
            </p>
            <p>
              <a routerLink="/NL/fabrikantlinks" target="_self">- Links naar fabrikanten ...</a>
            </p>
            <a class="text-more-info text-primary-hover" routerLink="/NL/fabrikantecatalogus" target="_self">Ontdek hier nog veel meer</a>
          </div>
        </div>
      </div>
    </section>-->
  <!-- Section 4 -->

  <!-- Section 5 -->
  <section class="section background-white hide-s hide-m">
    <div class="line">
      <h2 class="text-thin headline text-center text-s-size-30 margin-bottom-50">Aanbiedingen Fabrikanten</h2>
      <div class="carousel-default owl-carousel carousel-wide-arrows">
        <div class="item">
          <div class="margin">
            <div class="s-12 m-12 l-6">
              <div class="image-border-radius margin-m-bottom">
                <div class="margin">
                  <div class="s-12 m-12 l-4 margin-m-bottom">
                    <a class="image-hover-zoom" href="../../../assets/images/Aanbiedingen fabrikanten/Coltene promo tot eind maart 25 NL.pdf" target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/coltene afb web nl.png" alt="Coltene"></a>
                  </div>
                  <div class="s-12 m-12 l-8 margin-m-bottom">
          <h3><a class="text-dark text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/Coltene promo tot eind maart 25 NL.pdf" target="_blank">Aanbiedingen Coltene</a></h3>
          <p>Geldig tot 30 maart 2025.</p>
          <a class="text-more-info text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/Coltene promo tot eind maart 25 NL.pdf" target="_blank">Meer info</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="s-12 m-12 l-6">
              <div class="image-border-radius">
                <div class="margin">
                  <div class="s-12 m-12 l-4 margin-m-bottom">
                    <a href="../../../assets/images/Aanbiedingen fabrikanten/Kulzer promo eind maart 25 NL.pdf" target="_blank" class="image-hover-zoom"><img src="../../../assets/images/Aanbiedingen fabrikanten/Kulzer afb web nl.png" alt="Kulzer"></a>
                  </div>
                  <div class="s-12 m-12 l-8">
                    <h3><a class="text-dark text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/Kulzer promo eind maart 25 NL.pdf" target="_blank">Aanbiedingen Kulzer</a></h3>
                    <p>Geldig tot 31 maart 2025</p>
                    <a class="text-more-info text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/Kulzer promo eind maart 25 NL.pdf" target="_blank">Meer info</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="item">
          <div class="margin">
            <div class="s-12 m-12 l-6">
              <div class="image-border-radius margin-m-bottom">
                <div class="margin">
                  <div class="s-12 m-12 l-4 margin-m-bottom"> <a class="image-hover-zoom" href="../../../assets/images/Aanbiedingen fabrikanten/GC  promo tot april 25 NL.pdf" target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/gc afb web nl.png" alt="GC"></a> </div>
                  <div class="s-12 m-12 l-8 margin-m-bottom">
                    <h3><a class="text-dark text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/GC  promo tot april 25 NL.pdf" target="_blank">GC</a></h3>
                    <p>Geldig tot 30 april 2025</p>
                    <a class="text-more-info text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/GC  promo tot april 25 NL.pdf" target="_blank">Meer info</a> </div>
                </div>
              </div>
            </div>
            <div class="s-12 m-12 l-6">
              <div class="image-border-radius">
                <div class="margin">
                  <div class="s-12 m-12 l-4 margin-m-bottom"> <a href="../../../assets/images/Aanbiedingen fabrikanten/Ivoclar prop tot eind aug 25 NL.pdf" target="_blank" class="image-hover-zoom"><img src="../../../assets/images/Aanbiedingen fabrikanten/Ivoclar afb web nl.png" alt="Ivoclar"></a> </div>
                  <div class="s-12 m-12 l-8">
                    <h3><a class="text-dark text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/Ivoclar prop tot eind aug 25 NL.pdf" target="_blank">Ivoclar</a></h3>
                    <p>Geldig tot 31 augustus 2025</p>
                    <a class="text-more-info text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/Ivoclar prop tot eind aug 25 NL.pdf" target="_blank">Meer info</a> </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="margin">
            <div class="s-12 m-12 l-6">
              <div class="image-border-radius margin-m-bottom">
                <div class="margin">
                  <div class="s-12 m-12 l-4 margin-m-bottom"> <a class="image-hover-zoom" href="../../../assets/images/Aanbiedingen fabrikanten/My Lunos 2024 NL.pdf" target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/Durr dental web nl.png" alt="Durr dental"></a> </div>
                  <div class="s-12 m-12 l-8 margin-m-bottom">
                    <h3><a class="text-dark text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/My Lunos 2024 NL.pdf" target="_blank">Dürr Dental</a></h3>
                    <p>Nieuw in assortiment</p>
                    <a class="text-more-info text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/My Lunos 2024 NL.pdf" target="_blank">Meer info</a> </div>
                </div>
              </div>
            </div>
            <div class="s-12 m-12 l-6">
              <div class="image-border-radius">
                <div class="margin">
                  <div class="s-12 m-12 l-4 margin-m-bottom"> <a href="../../../assets/images/Aanbiedingen fabrikanten/3M brochure Filtek Easy Match Engels.pdf" target="_blank" class="image-hover-zoom"><img src="../../../assets/images/Aanbiedingen fabrikanten/3m afb web engels.png" alt="3M"></a> </div>
                  <div class="s-12 m-12 l-8">
                    <h3><a class="text-dark text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/3M brochure Filtek Easy Match Engels.pdf" target="_blank">SOLVENTUM</a></h3>
                    <p>Nieuw in assortiment</p>
                    <a class="text-more-info text-primary-hover" href="../../../assets/images/Aanbiedingen fabrikanten/3M brochure Filtek Easy Match Engels.pdf" target="_blank">Meer info</a> </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </section>



</main>
