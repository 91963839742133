    <!-- Midden -->
    <main role="main">

      <section>
        <article>
        <header class="section background-transparent text-center text-blue" data-parallax="scroll" data-image-src="../../assets/images/sparallax-01.jpg"> </header>
        <div class="background-white padding">
          <div class="line">
            <h2 class="text-size-30 text-s-size-12"><em>Conditions de vente</em></h2>
            <hr class="break break-small background-primary">
            <p><strong> Tous nos prix s'entendent hors TVA. </strong></p>
            <p>Nous exigeons le paiement des factures dans les 10 jours, date facture. Cette condition est essentielle pour nous permettre de poursuivre notre politique de prix. Si les conditions de paiement ne sont pas respectées, tous les frais de recouvrement seront à charge de l'acheteur. Quand une facture reste impayée après échéance, nous nous reservons le droit à un intérêt mensuel de 1%. De plus la créance sera forfaitairement majorée de 10% avec un minimum de € 25. En cas de procédure, seuls les tribunaux d'Anvers et le juge de paix du 1er Canton d'Anvers seront compétents.</p>
            <p class="margin-bottom-30"><strong>Les prix mentionnés sont de vigueur pendant la durée de ce catalogue, sauf fautes d'impression et modification de prix exceptionnelle.</strong> <br>
              La politique de prix agressive appliquée à un nombre restreint de produits pourrait entrainer des fluctuations importantes sur le marché ou des hausses de prix exceptionnelles émanant du fabricant. Des modifications de prix peuvent être appliquées dans ce cas, et uniquement dans ce cas. Ceci sans impact sur les prix de l'assortiment global présenté dans ce catalogue.<br>
            </p>
            <div class="line"> </div>
          </div>

          <!-- Icoontjes -->
          <div class="line">
            <hr class="break margin-bottom-70">
            <div class="margin">
              <div class="s-12 m-6 l-4 margin-bottom-60">
                <div class="float-left">
                  <i class="icon-pen background-primary icon-circle-small"></i>
                </div>
                <div class="margin-left-80">
                  <h4 class="text-uppercase text-strong">COMMENT PASSER COMMANDE</h4>
                  <p>De 08h30 à 17h00 </p>
                  <a class="text-more-info text-aqua-hover" routerLink="/FR/hoebestellen">Plus d'info</a></div>
              </div>
              <div class="s-12 m-6 l-4 margin-bottom-60">
                <div class="float-left">
                  <i class="icon-random background-primary icon-circle-small"></i>
                </div>
                <div class="margin-left-80">
                  <h4 class="text-uppercase text-strong">conditions de livraison<br>
                  </h4>
                  <p>Pour toute commande dépassant les € 150 Euro (hors TVA)</p>
                  <a class="text-more-info text-aqua-hover" routerLink="/FR/leveringsvoorwaarden">Plus d'info</a></div>
              </div>
              <div class="s-12 m-6 l-4 margin-bottom-60">
                <div class="float-left">
                  <i class="icon-star_empty background-primary icon-circle-small"></i>
                </div>
                <div class="margin-left-80">
                  <h4 class="text-uppercase text-strong">prix et ristournes</h4>
                  <p>Domiciliation 2% Vous recevez une réduction directe de 2% sur votre facture</p>
                  <a class="text-more-info text-aqua-hover" routerLink="/FR/kortingen">Plus d'info</a></div>
              </div>
</div>
          </div>
</div>
     <div class="background-dark padding text-center"> </div>
      </article>


      </section>

  </main>
