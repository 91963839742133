<main role="main">

        <section>
          <article>
                <header class="section background-transparent text-center text-blue" id="paralaxid" data-parallax="scroll" data-image-src="../../assets/images/parallax-01.jpg">
                </header>
          <div class="section background-white padding">
            <div class="line">
              <h2 class="text-size-30"><em>Comment passer commande?</em></h2>
              <hr class="break break-small background-primary">
              <p><strong>De 08h30 à 17h00</strong>&nbsp;<br>
                <strong>Tél /</strong> 03 226.00.81<br>
                <strong> &nbsp;</strong> <br>
                <strong> E-mail</strong>: order@Providental.com<br>
                <br>
                <strong> E-shop</strong>: vous commandez selon votre convenance par votre PC. De plus, le catalogue digital de Providental vous tient automatiquement au courant des dernières nouveautés et promotions.<br>
              </p>
              <p><strong> Attention</strong>:&nbsp;il y a 2 manières à chercher quelque chose: </p>
              <p>1) Par groupe principal, par groupe et par subgroupe: cliquer votre choix <br>
                2) ou par nom de produit, les deux manières ne cumulent pas </p>
              <p>&nbsp;</p>
              <div class="line"> </div>
            </div>

            <!-- Icoontjes -->
            <div class="line">
              <hr class="break margin-bottom-70">
              <div class="margin">
                <div class="s-12 m-6 l-4 margin-bottom-60">
                  <div class="float-left">
                    <i class="icon-discussion background-primary icon-circle-small"></i>
                  </div>
                  <div class="margin-left-80">
                    <h4 class="text-uppercase text-strong">CONDITIONS DE VENTE</h4>
                    <p>Tous nos prix s'entendent hors TVA. Nous exigeons le paiement des factures dans les 10 jours, date facture.</p>
                    <a class="text-more-info text-aqua-hover" routerLink="/FR/betalingsvoorwaarden" target="_self">Plus d'info</a></div>
                </div>
                <div class="s-12 m-6 l-4 margin-bottom-60">
                  <div class="float-left">
                    <i class="icon-random background-primary icon-circle-small"></i>
                  </div>
                  <div class="margin-left-80">
                    <h4 class="text-uppercase text-strong">CONDITIONS DE LIVRAISON<br>
                    </h4>
                    <p>Pour toute commande dépassant les € 150 Euro (hors TVA)</p>
                    <a class="text-more-info text-aqua-hover" routerLink="/FR/leveringsvoorwaarden" target="_self">Plus d'info</a></div>
                </div>
                <div class="s-12 m-6 l-4 margin-bottom-60">
                  <div class="float-left">
                    <i class="icon-star_empty background-primary icon-circle-small"></i>
                  </div>
                  <div class="margin-left-80">
                    <h4 class="text-uppercase text-strong">prix et ristournes</h4>
                    <p>Domiciliation 2% Vous recevez une réduction directe de 2% sur votre facture</p>
                    <a class="text-more-info text-aqua-hover" routerLink="/FR/kortingen" target="_self">Plus d'info</a></div>
                </div>
  </div>
            </div>
  </div>
       <div class="background-dark padding text-center"> </div>
        </article>


        </section>

        </main>
