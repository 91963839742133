<main role="main">

  <section>
    <article>
      <header class="section background-transparent text-center text-blue" id="paralaxid" data-parallax="scroll" data-image-src="../../assets/images/parallax-01.jpg">
      </header>
      <div class="section background-white padding">
        <div class="line">
          <h2 class="text-size-30">
            <em>Hoe bestellen</em>
          </h2>
          <hr class="break break-small background-primary">
          <p>
            <strong>Van 08.30u tot 17.00u (doorlopend)</strong>
            <br>
            <strong>Tel /</strong> 03 226.00.81
            <br>
            <strong> &nbsp;</strong> <br>
            <strong> E-mail</strong>: order@Providental.com
            <br>
            <strong> E-shop</strong>: de Providental catalogus waar U rechtstreeks via het internet kunt bestellen.
            <br>
            <br>
            <em>Providental On Line houdt U bovendien automatisch op de hoogte van nieuwigheden en promoties</em>.
            <br>
            <br>
            <strong>Opgelet, er zijn 2 manieren om iets op te zoeken:</strong>
            <br> 1) Via hoofdcategorie, groep en subgroep: uw keuze aanklikken
            <br> 2) of enkel het veld productnaam invullen de beiden niet cumuleren.</p>
          <div class="line"> </div>
        </div>

        <!-- Icoontjes -->
        <div class="line">
          <hr class="break margin-bottom-70">
          <div class="margin">
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-discussion background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">Betalingsvoorwaarden</h4>
                <p>Alle vermelde prijzen zijn exclusief BTW. Wij vragen een stipte betaling van de facturen binnen de 10 dagen
                  na factuurdatum</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/betalingsvoorwaarden">Lees meer</a>
              </div>
            </div>
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-random background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">leveringsvoorwaarden
                  <br>
                </h4>
                <p>Alle goederen worden zonder verzendkosten geleverd voor bestellingen van 150 Euro (exclusief BTW).</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/leveringsvoorwaarden">Lees meer</a>
              </div>
            </div>
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-star_empty background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">kortingen</h4>
                <p>U ontvangt 2% directe korting op uw factuur (niet cumuleerbaar met e-shopkorting)</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/kortingen">Lees meer</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="background-dark padding text-center"> </div>
    </article>


  </section>

</main>
