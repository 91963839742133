<!-- Midden -->
<main role="main">
  <section>
    <article>
      <header class="section background-green-hightlight background-transparent text-center background-blue"
        data-parallax="scroll" data-image-src="../../../assets/images/parallax-06.jpg">
        <h1 class="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1 text-s-size-16">
          D'offres des fabricants&nbsp;
        </h1>
      </header>
      <div class="section background-white padding">
        <div class="line">
          <!-- Catalogus -->

          <div class="line">
            <p class="icon-home text-blue">
              <a routerLink="/FR/home"> Home</a>
            </p>
            <h4 class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye">
              <a href="https://view.publitas.com/providental/promo-web-fr-providental" target="_blank">
                <strong>
                  <em> La brochure d'offres Providental</em>
                </strong>
              </a>
            </h4>
            <hr class="break break-small background-primary" />
            <div class="box margin-bottom">
              <div class="margin">
                <!-- CONTENT -->

                <!-- ASIDE NAV -->

                <div class="s-12 m-8 l-12">
                  <div class="line">
                <div class="s-12 m-6 l-3 margin-top-30"> <a href="../../../assets/images/Aanbiedingen fabrikanten/Coltene promo tot eind maart 25 FR.pdf" target="_blank">
                  <img src="../../../assets/images/Aanbiedingen fabrikanten/coltene afb web fr.png" alt="Coltene" width="158" height="222" class="center"></a>
                  <div>
                    <h5 class="text-uppercase text-strong text-center">Coltene</h5>
                    <a class="text-more-info text-aqua-hover text-center" href="../../../assets/images/Aanbiedingen fabrikanten/Coltene promo tot eind maart 25 FR.pdf" target="_blank">jusqu'au 31/03/2025</a></div>
                </div>
                <div class="s-12 m-6 l-3 margin-top-30"> <a href="../../../assets/images/Aanbiedingen fabrikanten/Ivoclar promo tot eind aug 25 Fr.pdf" target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/Ivoclar afb web fr.png" alt="Ivoclar" width="156" height="220" class="center"></a>
                  <div>
                    <h5 class="text-uppercase text-strong text-center">IVOCLAR</h5>
                    <a class="text-more-info text-aqua-hover text-center" href="../../../assets/images/Aanbiedingen fabrikanten/Ivoclar promo tot eind aug 25 Fr.pdf" target="_blank">jusqu'au 31/08/2025</a></div>
                </div>
                <div class="s-12 m-6 l-3 margin-top-30"> <a href="../../../assets/images/Aanbiedingen fabrikanten/GC  promo tot april 25 FR.pdf" target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/gc afb web fr.png" alt="GC" width="156" height="220" class="center"></a>
                  <div>
                    <h5 class="text-uppercase text-strong text-center">gc</h5>
                    <a class="text-more-info text-aqua-hover text-center" href="../../../assets/images/Aanbiedingen fabrikanten/GC  promo tot april 25 FR.pdf" target="_blank">jusqu'au 30/04/2025</a></div>
                </div>
                <div class="s-12 m-6 l-3 margin-top-30"> <a href="../../../assets/images/Aanbiedingen fabrikanten/Kulzer promo eind maart 25 FR.pdf" target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/Kulzer afb web fr.png" alt="Kulzer" width="158" height="222" class="center"></a>
                  <div>
                    <h5 class="text-uppercase text-strong text-center">KULZER</h5>
                    <a class="text-more-info text-aqua-hover text-center" href="../../../assets/images/Aanbiedingen fabrikanten/Kulzer promo eind maart 25 FR.pdf" target="_blank">jusqu'au 31/03/2025</a></div>
                </div>
                <div class="s-12 m-6 l-3 margin-top-30"> <a href="../../../assets/images/Aanbiedingen fabrikanten/Voco topprodukten flyer engels.pdf" target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/voco afb web fr.png" alt="Voco" width="155" height="218" class="center"></a>
                  <div>
                    <h5 class="text-uppercase text-strong text-center">VOCO</h5>
                    <a class="text-more-info text-aqua-hover text-center" href="../../../assets/images/Aanbiedingen fabrikanten/Voco topprodukten flyer engels.pdf" target="_blank">Top products by Voco&nbsp;</a></div>
                </div>
                <div class="s-12 m-6 l-3 margin-top-30"> <a href="../../../assets/images/Aanbiedingen fabrikanten/My Lunos 2024 FR.pdf" target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/Durr dental web fr.png" alt="3M" width="157" height="220" class="center"></a>
                  <div>
                    <h5 class="text-uppercase text-strong text-center">Dürr dental</h5>
                    <a class="text-more-info text-aqua-hover text-center" href="../../../assets/images/Aanbiedingen fabrikanten/My Lunos 2024 FR.pdf" target="_blank">Nouveau dans l'assortiment </a></div>
                </div>
                <div class="s-12 m-6 l-3 margin-top-30"> <a href="../../../assets/images/Aanbiedingen fabrikanten/3M brochure Filtek Easy Match Engels.pdf" target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/3m afb web engels.png" alt="Solventum" width="157" height="220" class="center"></a>
                  <div>
                    <h5 class="text-uppercase text-strong text-center">SOLVENTUM</h5>
                    <a class="text-more-info text-aqua-hover text-center" href="../../../assets/images/Aanbiedingen fabrikanten/3M brochure Filtek Easy Match Engels.pdf" target="_blank">Nouveau dans l'assortiment </a></div>
                </div>
                  </div>
                            </div>
                </div>
                            </div>
                </div>
                </div>
                      </div>
      <!-- Kleurbalk -->
      <div class="background-dark padding text-center"></div>
    </article>
  </section>
</main>
