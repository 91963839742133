<!-- Midden -->
<main role="main">

  <section>
    <article>
      <header class="section background-dark-hightlight background-transparent text-center background-blue"
        data-parallax="scroll" data-image-src="../../assets/images/parallax-06.jpg">
        <h1 class="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1">Fabricants </h1>
      </header>
      <div class="section background-white padding">
        <div class="line">
          <!-- Catalogus -->

          <div class="line">
            <p class="icon-home text-blue"><a routerLink="/FR/home" target="_self"> Home</a></p>
            <h2 class="text-size-30 text-s-size-12"><em>Links Fabricants</em></h2>
            <em>Le plus grand choix de marques!</em>
            <h4 class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye"><a
                routerLink="/FR/fabrikantecatalogus" target="_self"><strong><em> e-catalogue / pdf</em></strong></a>
            </h4>
            <h4 class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye"><a
                routerLink="/FR/fabrikantnieuweproducten" target="_self"><strong><em> Nouveau dans notre
                    assortiment</em></strong></a></h4>
            <hr class="break break-small background-primary">
            <div class="box margin-bottom">
              <div class="margin">
                <!-- CONTENT -->

                <!-- ASIDE NAV -->

                <div class="s-12 m-12 l-12">
                  <div class="line">
                  <div class="s-12 m-6 l-3 margin-top-60">
                                <img src="../../../assets/images/Logo's fabrikanten/3m.jpg" alt="Solventum" width="145" height="145" class="center">
                                <div><a class="text-more-info text-aqua-hover text-center" href="https://www.solventum.com/fr-be/home/oral-care" target="_blank">Plus d' info</a></div>
                              </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/agfa.jpg" alt="agfa" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.agfahealthcare.com/" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/alan.jpg" alt="Alan" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="http://www.alan.be" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/anthogyr.jpg" alt="Anthogyr" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.straumann.com/anthogyr/be/fr/home.html" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/asa.jpg" alt="Asa dental" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.asadental.com/fr/" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/bego.jpg" alt="Bego" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://france.bego.com/" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/busch.jpg" alt="Busch" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.busch.eu/fr/" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/carl martin.jpg" alt="Carl Martin" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="http://www.carlmartin.de" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/cavex.jpg" alt="Cavex" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="http://www.cavex.nl" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/coltene.jpg" alt="Coltene" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="http://www.coltene.com" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/dentsply.jpg" alt="Dentsply Sirona" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.dentsplysirona.com/fr-be" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/durr.jpg" alt="Durr dental" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.duerrdental.com/fr/BE/" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/edenta.jpg" alt="edenta" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.edenta.com/fr/" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/ems.jpg" alt="ems" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://ems-company.com/en" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/ethicon_logo.jpg" alt="ethicon" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.jnjmedtech.com/en-US/companies/ethicon" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/frasaco.jpg" alt="frasaco" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="http://www.frasaco.de" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/gc.jpg" alt="gc" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.gc.dental/europe/fr-BE" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/hager werken.jpg" alt="hager&werken" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="http://www.hagerwerken.de" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/hoffmann.jpg" alt="hoffmann" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://hoffmann-dental.com/?lang=fr" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/kulzer.jpg" alt="kulzer" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://kulzer.be/fr-b/fr-b/accueil/accueil.html" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/hu friedy.jpg" alt="Hu friedy" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://hufriedygroup.eu/fr" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/ivoclar.jpg" alt="Ivoclar" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="http://www.ivoclarvivadent.com" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/johnson.jpg" alt="Johnson&Johnson" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="http://www.jnj.com" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/kuraray.jpg" alt="Kuraray" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.kuraraynoritake.eu/be_fr/" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/oralb.jpg" alt="Oral-B" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.oralb.fr/fr-fr/" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/roeko.jpg" alt="Roeko" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.coltene.com/" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/ultradent.jpg" alt="Ultradent" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.ultradent.eu/" target="_blank">Plus d' info</a></div>
                            </div>
                            <div class="s-12 m-6 l-3 margin-top-60"> <img src="../../../assets/images/Logo's fabrikanten/voco.jpg" alt="Voco" width="145" height="145" class="center">
                              <div><a class="text-more-info text-aqua-hover text-center" href="https://www.voco.dental/fr/accueil.aspx" target="_blank">Plus d' info</a></div>
                            </div>
                </div>
                            </div>
                </div>
                            </div>
                </div>
                </div>
                      </div>
      <!-- Kleurbalk -->
    </article>


  </section>

</main>
